import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT_TOKEN } from 'src/environments/environment-token';

export type Platform = 'google' | 'github' | 'apple' | 'email'; // 'facebook' |
export interface AuthParams {
  platform: Platform;
  code: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface RegisterCredentials {
  email: string;
  password: string;
  repeatPassword: string;
}

export interface User {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  facebookUserId?: string;
  githubUserId?: number;
  googleUserId?: string;
  appleUserId?: string;
  permissionGroup?: 'user' | 'admin';
}

interface LoginRes {
  data: Omit<User, 'password'>;
  token: string;
  message: string;
}

interface RegisterRes {
  data: Omit<User, 'password'>;
  token: string;
  message: string;
}

interface LoginPlatformRes {
  data: Omit<User, 'password'>;
  token: string;
  message: string;
}

export type AuthRes = LoginRes | RegisterRes | LoginPlatformRes;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #http = inject(HttpClient);
  #env = inject(ENVIRONMENT_TOKEN);

  loginPlatform({ platform, code }: AuthParams) {
    return this.#http.post(`${this.#env.baseUrl}/token/${platform}?code=${code}`, null, {
      withCredentials: true,
    });
  }

  refreshToken() {
    return this.#http.post<{ accessToken: string }>(`${this.#env.baseUrl}/token/refresh`, null, {
      withCredentials: true,
    });
  }

  logout() {
    return this.#http.post<{ message: string }>(`${this.#env.baseUrl}/token/logout`, null, {
      withCredentials: true,
    });
  }
}
